import React, { memo } from 'react';

const HeroShape = ({ color = '#6feedb', className = '', ...rest }) => (
  <svg
    className={className}
    width="1400"
    height="674"
    viewBox="0 0 1400 674"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <circle cx="156.5" cy="236.5" r="12.5" fill={color} />
    <circle cx="1380" cy="519" r="20" fill={color} />
    <circle cx="605" cy="149" r="5" fill={color} />
    <circle cx="285" cy="219" r="5" fill={color} />
    <circle cx="1280" cy="273" r="5" fill={color} />
    <circle cx="55" cy="669" r="5" fill={color} />
    <circle cx="5" cy="99" r="5" fill={color} />
  </svg>
);

export default memo(HeroShape);
