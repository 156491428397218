import React from 'react';
import { Link } from 'gatsby';
import * as styles from './ProjectData.module.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const ProjectData = ({ contentData }) => {
  return (
    <div className={styles.projectData}>
      <div className={styles.projectDataWrapper}>
        {contentData.map((content, index) => (
          <div key={index} className={styles.projectDataContent}>
            {renderRichText(content)}
            {/*  <h3 className={styles.projectDataTitle}>{content.title}</h3>
            {content.subtitle && (
              <p className={styles.projectDataSubtitle}>{content.subtitle}</p>
            )}
            {content.link && (
              <Link to="/" className={styles.projectDataSubtitle}>
                {content.link}
              </Link>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectData;
