import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import useComponentFactory from '../hooks/useComponentFactory';
import PageDataContext from '../state/PageDataContext';
import { Content } from '@vccp/bernadette-components';
import ProjectData from '../components/ProjectData/ProjectData';
import Seo from '../components/Seo/Seo';
import Hero from '../components/Hero/Hero';
import * as styles from './project.module.scss';
import PageTransition from 'gatsby-plugin-page-transitions';

const ProjectPageTemplate = ({ data, location, ...rest }) => {
  const pageData = data.contentfulProject;
  const components = useComponentFactory(pageData.components);
  const projectData = [
    pageData.projectData1,
    pageData.projectData2,
    pageData.projectData3,
    pageData.projectData4,
  ].filter((data) => Boolean(data?.raw));

  return (
    <PageTransition>
      <PageDataContext.Provider value={{ ...pageData, ...rest, location }}>
        <Seo
          contentTitle={pageData.title}
          pathname={location.pathname}
          seo={pageData.seo}
        />
        <Layout>
          <Hero
            title={pageData.client.clientName}
            subtitle={pageData.title}
            image={pageData.heroImage}
            className={styles.projectHeroContainer}
            color={`#${pageData.client.clientAccent}`}
          />
          <Content>
            {projectData.length > 0 && (
              <ProjectData contentData={projectData} />
            )}
          </Content>
          {pageData.missionStatement.missionStatement && (
            <h2
              style={{
                color: '#6feedb',
              }}
              className={styles.projectMissionStatement}>
              {pageData.missionStatement.missionStatement}
            </h2>
          )}

          {components}
        </Layout>
      </PageDataContext.Provider>
    </PageTransition>
  );
};

export const pageQuery = graphql`
  query ProjectPageById($id: String!) {
    contentfulProject(id: { eq: $id }) {
      title
      publishDate
      priorityProject
      themeColour
      seo {
        canonicalUrl
        description
        seoTitle
        image {
          file {
            url
          }
          title
          width
          height
        }
        hidePageFromSearchEngines
        excludeFromSitemap
      }
      client {
        clientName
        clientAccent
        clientHeaderBackground
        clientHeaderForeground
      }
      heroImage {
        gatsbyImageData
        file {
          url
        }
        title
      }
      heroVideo
      projectData1 {
        raw
      }
      projectData2 {
        raw
      }
      projectData3 {
        raw
      }
      projectData4 {
        raw
      }
      missionStatement {
        missionStatement
      }
      description {
        description
      }
      components {
        __typename

        ... on ContentfulAward {
          ...ContentfulAwardFields
        }
        ... on ContentfulComponentAwards {
          ...ContentfulComponentAwardsFields
        }
        ... on ContentfulComponentFullWidthImage {
          ...ContentfulComponentFullWidthImageFields
        }
        ... on ContentfulComponentGetInTouch {
          ...ContentfulComponentGetInTouchFields
        }

        ... on ContentfulComponentImageCarousel {
          ...ContentfulComponentImageCarouselFields
        }
        ... on ContentfulComponentPageHeader {
          ...ContentfulComponentPageHeaderFields
        }
        ... on ContentfulComponentQuote {
          ...ContentfulComponentQuoteFields
        }
        ... on ContentfulComponentShareStory {
          ...ContentfulComponentShareStoryFields
        }
        ... on ContentfulComponentProjectSpotlight {
          ...ContentfulComponentProjectSpotlightFields
        }
        ... on ContentfulComponentStats {
          ...ContentfulComponentStatsFields
        }
        ... on ContentfulComponentText {
          ...ContentfulComponentTextFields
        }
        ... on ContentfulComponentVideo {
          ...ContentfulComponentVideoFields
        }
        ... on ContentfulComponentHeading {
          ...ContentfulComponentHeadingFields
        }
        ... on ContentfulComponentTwoColumnImageAndDescription {
          ...ContentfulComponentTwoColumnImageAndDescriptionFields
        }
        ... on ContentfulComponentRelatedContent {
          ...ContentfulComponentRelatedContentProjectsFields
        }
        ... on ContentfulComponentAnimatedHeading {
          ...ContentfulComponentAnimatedHeadingFields
        }
        ... on ContentfulComponentThreeColumnContent {
          ...ContentfulComponentThreeColumnContentFields
        }
      }
    }
  }
`;

export default ProjectPageTemplate;
