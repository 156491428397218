import React from 'react';
import ParallaxWithBgr from '../ParallaxWithBgr/ParallaxWithBgr';
import HeroShape from '../svgs/HeroShape';
import * as styles from './Hero.module.scss';
import { ProjectPageHeading, Content } from '@vccp/bernadette-components';
import Image from '../Image/Image';

// @TODO add full width / no full width option

const Hero = ({ title, subtitle, image, color }) => {
  return (
    <div className={styles.hero}>
      <ParallaxWithBgr
        background={<HeroShape color={color} className={styles.bgr} />}>
        <ProjectPageHeading clientName={title} projectTitle={subtitle} />
        <Content>
          <Image {...image} />
        </Content>
      </ParallaxWithBgr>
    </div>
  );
};

export default Hero;
